<template>
  <div v-click-outside="onClickOutside" class="vps-autoprolong">
    <v-popover
      placement="top"
      :popper-options="popperOptions"
      :auto-hide="true"
      :open="isPopoverOpen"
      trigger="manual"
      popover-class="tariff-card__popover"
    >
      <plain-button
        :icon="icons[autoprolongSettings.state]"
        class="vps-autoprolong__trigger"
        @click="togglePopoverState"
      />
      <template #popover>
        <div ref="popover" class="vps-autoprolong__popover">
          <div class="vps-autoprolong__text standart-text">
            {{
              $t(`autoprolong.state.${autoprolongSettings.state}`, {
                period: autoprolongSettings.period,
              })
            }}
          </div>
          <div class="vps-autoprolong__btns">
            <base-button
              size="small"
              class="vps-autoprolong__btn"
              :loading="isLoading"
              @click="onEdit(tariff, autoprolongSettings.state === 'on' ? 'update' : 'on')"
            >
              {{
                autoprolongSettings.state === 'on'
                  ? $t('autoprolong.actions.update')
                  : $t('autoprolong.actions.on')
              }}
            </base-button>
            <base-button
              v-if="autoprolongSettings.state === 'on'"
              theme="outlined"
              size="small"
              class="vps-autoprolong__btn"
              @click="autoprolong(tariff, 'off')"
            >
              {{ $t('autoprolong.actions.off') }}
            </base-button>
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
import VpsProlongSinglePayments from './VpsProlongSinglePayments.vue';
// import VpsProlongSingle from './VpsProlongSingle.vue';
import { VpsTariff } from '@/models/BillMgr/VpsTariff';
import prolongAuto from '@/mixins/billmgr/prolongAuto';
export default {
  name: 'VpsAutoprolong',
  mixins: [prolongAuto],
  props: {
    tariff: {
      type: VpsTariff,
      required: true,
      validator: inst => inst instanceof VpsTariff,
    },
  },
  data() {
    return {
      icons: {
        on: 'autoprolong-checked',
        off: 'autoprolong',
      },
      isPopoverOpen: false,
      popperOptions: {
        modifiers: {
          flip: {
            padding: { top: 60 },
          },
        },
      },
      isLoading: false,
      baseClass: 'vps-autoprolong',
      moduleMain: 'moduleVPS',
      // singleProlongComponent: VpsProlongSingle,
      singleProlongComponent: VpsProlongSinglePayments,
    };
  },
  computed: {
    hasAutoprolong() {
      return this.tariff.autoprolongAvailable;
    },
    autoprolongSettings() {
      return this.tariff.autoprolongSettings;
    },
    autoprolongList() {
      return this.autoprolongSettings.list;
    },
  },
  methods: {
    togglePopoverState(value) {
      this.isPopoverOpen = !(value instanceof Event) ? value : !this.isPopoverOpen;
    },
    onClickOutside(e) {
      if (e.usedByTooltip) return;
      if (this.$refs.popover !== e.target.closest(`.${this.baseClass}__popover`)) {
        this.togglePopoverState(false);
      }
    },
    onEdit(tariff, action) {
      if (this.autoprolongSettings.list.length) {
        // console.log(tariff, action);
        this.autoprolong(tariff, action);
      } else {
        this.isLoading = true;
        this.$store
          .dispatch('moduleVPS/fetchDetail', tariff.id)
          .then(() => {
            this.autoprolong(tariff, action);
          })
          .finally(() => (this.isLoading = false));
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "autoprolong": {
      "title": "Автопродление",
      "state": {
        "on": "Включено автоматическое продление на {period}",
        "off": "Автоматическое продление выключено"
      },
      "actions": {
        "on": "Включить",
        "off": "Выключить",
        "update": "Изменить"
      },
      "modals": {
        "off": {
          "title": "Отключить автопроделние",
          "desc": "Автопродление для услуги #{id} будет отключено",
          "confirm": "Да, отключить"
        },
        "update": {
          "title": "Изменить настройки автопродления",
          "confirm": "Сохранить"
        },
        "on": {
          "title": "Включить автопродление",
          "confirm": "Включить"
        }
      },
      "result": {
        "success": {
          "title": "Отправлено успешно",
          "desc": "Настройки автопродления для услуги #{id} были успешно изменены",
          "confirm": "Хорошо"
        },
        "error": {
          "title": "Произошла ошибка",
          "desc": "Настройки автопродления для услуги #{id} изменить не удалось. Попробуйте позднее или обратитесь за помощью в поддержку",
          "confirm": "Понятно"
        }
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-autoprolong {
  line-height: 1;

  &__btns {
    margin: 1rem -0.5rem -0.5rem;
    flexy(flex-start, center, wrap);
  }

  &__btn {
    margin: 0.5rem 0.5rem;
  }

  &__popover {
    width: calc(100vw - 3.25rem);

    +breakpoint(sm-and-up) {
      width: 260px;
    }
  }
}
</style>
